import React from 'react';
import styled from "styled-components";
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  font-family: Arial Narrow;
  font-size: 14px;
  line-height: 1.33;
  text-align: center;
  padding: 60px 0 20px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    width: 100%;
    height: 10px;
    position: absolute;
    top: -5px;
    background: white;
    left: 0;
  }

  ${media.large`
    padding: 120px 0 40px;
  `}
`
const Footer = (props) => {
  return (
    <Wrapper>
      © 2021 Myrch.Club. All rights reserved 
    </Wrapper>
  )
}

export default Footer;