import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { media } from '../../utils/mediaQuery';
import HomeHead from '../HomeHead/HomeHead';
import ShadowContainer from '../../components/ShadowContainer/ShadowContainer';
import Blocks from '../Blocks/Blocks';
import Footer from '../../components/Footer/Footer';
import { selectTag, triggerToggle, setArrowAngle } from '../../actions';

const Spacer = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  pointer-events: none;
`

const Dummy = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${props => props.background || 'white' };
  z-index: 0;
  transform: translate3d(0,0,0);
  overflow: hidden;
`

const DummyBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 25%;
  width: 100%;
  background: white;
`

const DummyTop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${props => props.background || 'white' };
  z-index: 5;
  transform: translate3d(0,0,0);
  overflow: hidden;
  //clip-path: ${props => props.path ? 'polygon(0 0,100% 0,100% 93%,99.7% 89%,99% 83%,1% 83%,0.3% 89%,0 93%)' : 'polygon(0 0,100% 0,100% 70%,99.7% 66%,99% 60%,1% 60%,0.3% 66%,0 70%)'};
  ${media.large`
    clip-path: polygon(0 0,100% 0,100% 95%,99.7% 91%,99% 85%,1% 85%,0.3% 91%,0 95%);
  `}
`

const DummyShadow = styled.div`
  will-change: transform;
  opacity: 0;
`

const DummyToggle = styled.button`
  position: relative;
`

const MainShadow = styled.div`
  position: absolute; 
  //bottom: -2px; 
  width: 100%; 
  left: 0; 
  z-index: 5;

  ${media.large`
    //bottom: -18px; 
  `}
`

const Scroll = styled.div`
position: relative;
width: 100%;
z-index: 3;
`

const Main = (props) => {
  const { document, context } = props;
  const utilRedux = useSelector(state => state.utilReducer);
  const dispatch = useDispatch();
  const [ GridPos, setGridPos] = useState();
  const [ ClipPath, setClipPath ] = useState(false);
  const initialSet = useRef(false);
  const gridEl = useRef();
  const shadowEl = useRef();
  const mainShadowEl = useRef();
  const dummyEl = useRef();
  const dummyTopEl = useRef();
  const spacerEl = useRef();
  const animateFuse = useRef(false);
  const isReachedThresh = useRef(false);

  useEffect(()=>{
    dispatch(selectTag(context && context.tag_name ? context.tag_name : null));
    window.addEventListener('scroll', onScroll);
    shadowEl.current.addEventListener('transitionend', function() {
      shadowEl.current.style.transition = '';
      dummyEl.current.style.transition = '';
      console.log('transition deleted');
    })

    shadowEl.current.style.position = 'fixed';
    shadowEl.current.style.width = '100%';
    shadowEl.current.style.zIndex = 5;

    dummyTopEl.current.style.height = `${utilRedux.gridThresh+shadowEl.current.offsetHeight}px`;

    if(utilRedux.spacerHeight && !initialSet.current) {  
      //mainShadowEl.current.style.display = 'block';
      if(!utilRedux.spacerInit) {
        stickShadow();
      } else {
        stickShadow(utilRedux.spacerHeight);
      }
     initialSet.current = true;
    } else if(utilRedux.spacerHeight && initialSet.current) {
      if(animateFuse.current) {
        stickShadow();
      } else {
        console.log('how');
        stickShadow();
      }

    }

    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
    }
  }, [utilRedux.spacerHeight]);

  const onScroll = (e) => {
    //console.log(e);
    stickShadow();
  }

  const stickShadow = (topVal, animate) => {
    if(gridEl.current) {
      let gridTop = topVal || utilRedux.spacerHeight - window.scrollY;
      let opacity = 1 - ((gridTop - utilRedux.gridThresh) / 60);
      const angle = Math.round(180 - (180 * (gridTop - utilRedux.gridThresh) / (utilRedux.spacerHeight - utilRedux.gridThresh)));

      if(window.scrollY === 0) {
        dispatch(triggerToggle(true));
      } else {
        dispatch(triggerToggle(false));
      } 

      dispatch(setArrowAngle(angle <= 180 ? angle : 180));
      shadowEl.current.style.opacity = opacity >= 0 ? opacity < 1 ? opacity: 1 : 0;
      if(gridTop <= utilRedux.gridThresh && !isReachedThresh.current) { 
        isReachedThresh.current = true;
        gridTop = utilRedux.gridThresh;
        //shadowEl.current.style.opacity = 1;
        //mainShadowEl.current.style.display = 'none';
        console.log('reached thresh')
        //dummyTopEl.current.style.height = `${gridTop+40}px`;
        
        setClipPath(true);

      } else if(gridTop > utilRedux.gridThresh && isReachedThresh.current){
        console.log('toggle off thresh')
        isReachedThresh.current = false;
        //shadowEl.current.style.opacity = 0;
        //dummyTopEl.current.style.height = `${utilRedux.gridThresh+40}px`;
        setClipPath(false);
        //mainShadowEl.current.style.display = 'block';
      }

      if(!isReachedThresh.current) {
        //dummyEl.current.style.height = `auto`;
        shadowEl.current.style.transform = `translate(0,${gridTop}px)`;
      } else {
        shadowEl.current.style.transform = `translate(0,${utilRedux.gridThresh}px)`;
      }

      
      

      if(animate) {
        console.log('animate');
        //shadowEl.current.style.transition = 'top .1s ease';
        //dummyEl.current.style.transition = 'height .1s ease';
        animateFuse.current = true;
      } else if(animateFuse.current) {
        console.log('animate fuse');
        animateFuse.current = false;
        //shadowEl.current.style.transition = 'top .1s ease';
        //dummyEl.current.style.transition = 'height .1s ease';
      }    
      
    }

  }

  if (!document) return null

  if(utilRedux.gridThresh && shadowEl.current) {
    //shadowEl.current.style.transform = `translate(0,${utilRedux.gridThresh+18}px)`;
  }
  

  return (
    <>
      <DummyTop ref={dummyTopEl} path={ClipPath} background={context && context.background ? context.background : null }><HomeHead theme={context}/></DummyTop>
      <Dummy ref={dummyEl} background={context && context.background ? context.background : null }>
        <HomeHead theme={context}/>
      </Dummy>
      <DummyShadow ref={shadowEl}>
        <ShadowContainer/>
      </DummyShadow>
      <Spacer ref={spacerEl} height={utilRedux && utilRedux.spacerHeight ? utilRedux.spacerHeight : '448'}/>
      <Scroll>
        <div ref={gridEl} style={{position: 'relative'}}>  
          <Blocks blocks={document.edges}/>
          <Footer />
        </div>
      </Scroll>
      <DummyBottom />
    </>
  )
}

export default Main;